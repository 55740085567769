/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'box-arrow-in-down-left': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M9.636 2.5a.5.5 0 00-.5-.5H2.5A1.5 1.5 0 001 3.5v10A1.5 1.5 0 002.5 15h10a1.5 1.5 0 001.5-1.5V6.864a.5.5 0 00-1 0V13.5a.5.5 0 01-.5.5h-10a.5.5 0 01-.5-.5v-10a.5.5 0 01.5-.5h6.636a.5.5 0 00.5-.5"/><path pid="1" fill-rule="evenodd" d="M5 10.5a.5.5 0 00.5.5h5a.5.5 0 000-1H6.707l8.147-8.146a.5.5 0 00-.708-.708L6 9.293V5.5a.5.5 0 00-1 0z"/>',
    },
});
